import React from 'react';

function Modal({ show, modalTitle = "", message, buttons = [], onClose = null }) {
    if (!show) {
        return null;
    }

    return (
        <>
            <div className="modal-backdrop fade show"></div>
            <div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{modalTitle}</h5>
                            <button type="button" className="btn-close" onClick={onClose}></button>
                        </div>
                        <div className="modal-body">
                            <p>{message}</p>
                        </div>
                        <div className="modal-footer">
                            {buttons.map((button, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    className={`btn ${button.className}`}
                                    onClick={button.action}
                                >
                                    {button.name}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Modal;