import { React, useState } from "react";
import Toast from '../utils/toast';
import Modal from "../utils/modal";
import { usersService } from "../../services/user-services/users";

function ForgotPassword() {

    const [email, setEmail] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const showErrorToast = (message) => {
        setToastMessage(message);
        setShowToast(true);
    };

    const handeSubmit = async () => {
        try {
            await usersService.forgotPassword(email).then((response) => {
                setModalMessage(response.data);
                setShowModal(true)
            });
        }
        catch (error) {
            showErrorToast(error.response.data.title);
        }
    };

    return (
        <div className="container">
                    <h4>Ingresa tu dirección de correo electrónico para restablecer tu contraseña.</h4>
                    <form>
                        <div className="mb-3">
                            <label className="form-label required">Correo electrónico</label>
                            <input type="email" className="form-control" id="email" name="email" required onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </form>
            <Toast show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            <button type="submit" className="btn btn-primary" onClick={handeSubmit}>Restablecer contraseña</button>
            <Modal
                show={showModal}
                message={modalMessage}
                buttons={[{name: 'Cerrar', className: 'btn-primary', action: () => [setShowModal(false), window.location.href = "/login"] }]}
            />
        </div>
    );
}

export default ForgotPassword;