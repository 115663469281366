import { api } from '../api-services'

const url = "/users"

const login = async(email, password) => {
    return await api.post('/users/login', {
        email,
        password
    },
        false
    );
}

const authenticate = async(googleToken) => {
    return await api.post(`${url}/google/auth`, { googleToken });
};

const createUser = (user) => {
    return api.post(url, user);
}

const updateUser = (user) => {
    const body = {
      ...user
    }
    delete body.id;
    return api.put(`${url}/${user.id}`, body);
}

const getUser = (id) => {
    return api.get(`${url}/${id}`)
}

const confirmUser = (token) => {
    return api.get(`${url}/confirm?token=${token}`)
}

const forgotPassword = async (email) => {
    return api.post(`${url}/forgot-password`, { email });
}

const resetPassword = (email, token, password) => {
    return api.post(`${url}/reset-password`, { email, token, password });
}

export const usersService = {
    login,
    authenticate,
    createUser,
    updateUser,
    getUser,
    confirmUser,
    forgotPassword,
    resetPassword,
}