import axios from 'axios';
import environment from '../environments/environment';

const client = axios.create({
    baseURL: environment.API_DOMAIN,
});

const get = async(url, token = true, params = null) => {
    return client.get(url, {
        headers: {
            Authorization: token ? `Bearer ${localStorage.getItem('current-token')}` : null
        },
        params
    })
}

async function post(url, data, token = true, customHeaders = {}) {
    const headers = {
        ...customHeaders,
        Authorization: token ? `Bearer ${localStorage.getItem('current-token')}` : null
    };
    return await client.post(url, data, { headers });
}


const put = async(url, data, token = true, customHeaders = {}) => {
    const headers = {
        ...customHeaders,
        Authorization: token ? `Bearer ${localStorage.getItem('current-token')}` : null
    };
    return await client.put(url, data, { headers });
}

const del = async(url, token = true) => {
    const response = await client.delete(url, {
        headers: {
            Authorization: token ? `Bearer ${localStorage.getItem('current-token')}` : null
        }
    });
    return response;
}

export const api = {
    get,
    post,
    put,
    del
}