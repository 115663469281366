import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Toast from '../utils/toast';
import AdBanner from '../utils/ad-banner';
import { useUserHooks } from './user.hooks';

function SignUp() {

    const [activeTab, setActiveTab] = useState('consignee');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const { createUser } = useUserHooks();

    const showErrorToast = (message) => {
        setToastMessage(message);
        setShowToast(true);
    };

    const [confirmPassword, setConfirmPassword] = useState('');

    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        business: '',
        RUT: '',
        location: '',
        email: '',
        password: '',
        telephone: '',
        logo: null,
        role: activeTab
    });

    const handleChangeTab = (e) => {
        setActiveTab(e.target.value);
        setUserData(prevState => ({
            ...prevState,
            firstName: '',
            lastName: '',
            business: '',
            RUT: '',
            location: '',
            email: '',
            password: '',
            telephone: '',
            logo: null,
            role: e.target.value
        }));
    };

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setUserData({ ...userData, logo: reader.result });
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (userData.password !== confirmPassword) {
            showErrorToast("Las contraseñas no coinciden");
            return;
        }
        try {
            await createUser(userData).then(() => {
                window.location.href = "/confirm";
            });
        } catch (error) {
            showErrorToast(error.response.data.title);
        }
    };

    return (
        <>
            <AdBanner></AdBanner>
            <div className='container'>
                <h1>Registrarse</h1>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <button className={`nav-link ${activeTab === 'consignee' ? 'active' : ''}`} value="consignee" onClick={handleChangeTab}>
                            Consignatario
                        </button>
                    </li>
                    <li className="nav-item">
                        <button className={`nav-link ${activeTab === 'client' ? 'active' : ''}`} value="client" onClick={handleChangeTab}>
                            Cliente
                        </button>
                    </li>
                </ul>

                <div className="tab-content">
                    {activeTab === 'consignee' && (
                        <div className="tab-pane active">
                            <h4 className='mt-3 text-center'>Nuevo Consignatario</h4>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="form-label required">Nombre del negocio</label>
                                    <input className="form-control"
                                        type="text" name="business"
                                        required onChange={handleChange} />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">RUT</label>
                                    <input className="form-control"
                                        type="text" name="RUT"
                                        onChange={handleChange} />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="Location" className="form-label required">Departamento</label>
                                    <select className="form-select" name="location" onChange={handleChange}>
                                        <option selected>Selecciona un departamento</option>
                                        <option value="Artigas">Artigas</option>
                                        <option value="Canelones">Canelones</option>
                                        <option value="Cerro Largo">Cerro Largo</option>
                                        <option value="Colonia">Colonia</option>
                                        <option value="Durazno">Durazno</option>
                                        <option value="Flores">Flores</option>
                                        <option value="Florida">Florida</option>
                                        <option value="Lavalleja">Lavalleja</option>
                                        <option value="Maldonado">Maldonado</option>
                                        <option value="Montevideo">Montevideo</option>
                                        <option value="Paysandú">Paysandú</option>
                                        <option value="Río Negro">Río Negro</option>
                                        <option value="Rivera">Rivera</option>
                                        <option value="Rocha">Rocha</option>
                                        <option value="Salto">Salto</option>
                                        <option value="San José">San José</option>
                                        <option value="Soriano">Soriano</option>
                                        <option value="Tacuarembó">Tacuarembó</option>
                                        <option value="Treinta y Tres">Treinta y Tres</option>
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label required">Nombre</label>
                                    <input className="form-control"
                                        type="text" name="firstName"
                                        required onChange={handleChange} />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label required">Apellido</label>
                                    <input className="form-control"
                                        type="text" name="lastName"
                                        required onChange={handleChange} />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label required">Correo electrónico</label>
                                    <input className="form-control"
                                        type="email" name="email"
                                        required onChange={handleChange} />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label required">Contraseña</label>
                                    <input className="form-control"
                                        type="password" name="password"
                                        required onChange={handleChange} />
                                    <div className="form-text" id="basic-addon4">Mínimo 8 caracteres. Al menos una mayúscula y un número</div>

                                </div>

                                <div className="mb-3">
                                    <label className="form-label required">Confirmar contraseña</label>
                                    <input className="form-control"
                                        type="password" name="ConfirmPassword"
                                        required onChange={(e) => setConfirmPassword(e.target.value)} />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label required">Contacto</label>
                                    <input className="form-control"
                                        type="text" name="telephone"
                                        placeholder="Celular de contacto"
                                        required onChange={handleChange} />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="content" className="form-label">Logo</label>
                                    <input className="form-control"
                                        type="file" name="logo" accept="image/*"
                                        onChange={handleFileChange} required
                                    />
                                </div>

                                <p>Los campos marcados con <span style={{ "color": "red" }} >*</span> son obligatorios.</p>

                                <button type="submit" className="btn btn-primary">Registrarse</button>
                                <NavLink to='/login' className="btn">Ya tienes cuenta? <span className='btn-link'>Inicia sesión</span></NavLink>
                            </form>
                        </div>
                    )}
                    {activeTab === 'client' && (
                        <div className="tab-pane active">
                            <h4 className='mt-3 text-center'>Nuevo Cliente</h4>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label className="form-label required">Nombre</label>
                                    <input className="form-control"
                                        type="text" name="firstName"
                                        required
                                        onChange={handleChange} />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label required">Apellido</label>
                                    <input className="form-control"
                                        type="text" name="lastName"
                                        required onChange={handleChange} />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label required">Correo electrónico</label>
                                    <input className="form-control"
                                        type="email" name="email"
                                        required onChange={handleChange} />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label required">Contraseña</label>
                                    <input className="form-control"
                                        type="password" name="password"
                                        required onChange={handleChange} />
                                    <div className="form-text" id="basic-addon4">Mínimo 8 caracteres. Al menos una mayúscula y un número</div>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label required">Confirmar contraseña</label>
                                    <input className="form-control"
                                        type="password" name="ConfirmPassword"
                                        required onChange={(e) => setConfirmPassword(e.target.value)} />
                                </div>

                                <p>Los campos marcados con <span style={{ "color": "red" }} >*</span> son obligatorios.</p>

                                <button type="submit" className="btn btn-primary">Registrarse</button>
                                <NavLink to='/login' className="btn">Ya tienes cuenta? <span className='btn-link'>Inicia sesión</span></NavLink>
                            </form>
                        </div>
                    )}
                </div>
                <Toast show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            </div>
        </>
    );
}

export default SignUp;