import React, { useState, useEffect } from 'react';
import LoadingScreen from '../utils/loading-screen';
import { CreateContent } from '../contents/create';
import AdBanner from '../utils/ad-banner';
import { usePostsHooks } from './posts.hooks';
import Loading from '../utils/loading';

export default function PostCreate() {

    const { isLoading } = Loading();
    const { createPost, fetchBreeds, fetchCategories } = usePostsHooks();

    const [postForm, setPostForm] = useState({
        title: '',
        description: '',
        price: 0,
        location: '',
        ubication: '', 
        // sold: false,
        isPublic: true,
        isActive: true, 
        lot: {
            specie: 'cattle',
            breeds: [],
            categories: [],
            weight: 0,
            age: '0 a 1',
            quantity: 0
        },
        media: [] 
    });

    const [categories, setCategories] = useState([]);
    const [breeds, setBreeds] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const breedsResponse = await fetchBreeds(postForm.lot.specie);
            const categoriesResponse = await fetchCategories(postForm.lot.specie);
            setBreeds(breedsResponse);
            setCategories(categoriesResponse);
            setPostForm((prevForm) => ({
                ...prevForm,
                lot: { 
                    ...prevForm.lot, 
                    categories: [categoriesResponse[0]?.id || ''], 
                    breeds: [breedsResponse[0]?.id || ''] 
                }
            }));
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postForm.lot.specie]);

    const handleFilesSelected = (selectedFiles) => {
        setPostForm((prevForm) => ({
            ...prevForm,
            media: selectedFiles  
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await createPost(postForm);
        window.location.href = "/";
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPostForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    const handleLotChange = (e) => {
        const { name, value } = e.target;
        setPostForm((prevForm) => ({
            ...prevForm,
            lot: { ...prevForm.lot, [name]: value }
        }));
    };

    const handleCategoriesChange = (e) => {
        let selectedValues = Array.from(e.target.selectedOptions, option => option.value);
        setPostForm((prevForm) => ({
            ...prevForm,
            lot: { ...prevForm.lot, categories: selectedValues }
        }));
    };

    const handleBreedsChange = (e) => {
        let selectedValues = Array.from(e.target.selectedOptions, option => option.value);
        setPostForm((prevForm) => ({
            ...prevForm,
            lot: { ...prevForm.lot, breeds: selectedValues }
        }));
    };

    return (
        <>
            <AdBanner></AdBanner>
            {isLoading && <LoadingScreen />}
            <div className='container'>
                <h1>Crea una nueva publicación</h1>
                <form onSubmit={handleSubmit}>

                    <div className="mb-3">
                        <label htmlFor="Title" className="form-label">Título</label>
                        <input type="text" className="form-control"
                            id="Title"
                            name="title"
                            required
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="Specie" className="form-label">Especie</label>
                        <select className="form-select"
                            id="Specie"
                            name="specie"
                            value={postForm.lot.specie}
                            required
                            onChange={handleLotChange}
                        >
                            <option value="">Seleccione una especie</option>
                            <option key="cattle" value="cattle">Vacunos</option>
                            <option key="sheep" value="sheep">Ovinos</option>
                            <option key="horse" value="horse">Equinos</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="Breed" className="form-label">Raza</label>
                        <select className="form-select" multiple size="4"
                            id="Breed"
                            name="breeds"
                            onChange={handleBreedsChange}
                        >
                            <option disabled value="">Seleccione una raza</option>
                            {breeds.map((breed) => {
                                return (
                                    <option key={breed.id} value={breed.id}>{breed.name}</option>);
                            })}
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="Category" className="form-label">Categoría</label>
                        <select className="form-select" multiple size="4"
                            id="Category"
                            name="categories"
                            required
                            onChange={handleCategoriesChange}
                        >
                            <option disabled value="">Seleccione una categoría</option>
                            {categories.map((category) => {
                                return (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                );
                            })}
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="Quantity" className="form-label">Cantidad</label>
                        <input className="form-control"
                            id="Quantity"
                            name="quantity"
                            type="number"
                            min="0"
                            required
                            onChange={handleLotChange}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="Price" className="form-label">Precio</label>
                        <input className="form-control"
                            id="Price"
                            name="price"
                            type="number"
                            min="0"
                            step="0.01"
                            required
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="Weight" className="form-label">Peso estimado</label>
                        <input className="form-control"
                            id="Weight"
                            name="weight"
                            type="number"
                            min="0"
                            step="0.01"
                            required
                            onChange={handleLotChange}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="Age" className="form-label">Edad</label>
                        <select className="form-select" id="Age" name="age" onChange={handleLotChange}>
                            <option value="0 a 1">0 a 1</option>
                            <option value="1 a 2">1 a 2</option>
                            <option value="2 a 3">2 a 3</option>
                            <option value="4+">+3</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="Location" className="form-label">Localidad</label>
                        <input className="form-control"
                            id="Location"
                            name="location"
                            type="string"
                            required
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="Ubication" className="form-label">Departamento</label>
                        <select className="form-select" id="Ubication" name="ubication" onChange={handleInputChange}>
                            <option value="">Selecciona un departamento</option>
                            <option value="Artigas">Artigas</option>
                            <option value="Canelones">Canelones</option>
                            <option value="Cerro Largo">Cerro Largo</option>
                            <option value="Colonia">Colonia</option>
                            <option value="Durazno">Durazno</option>
                            <option value="Flores">Flores</option>
                            <option value="Florida">Florida</option>
                            <option value="Lavalleja">Lavalleja</option>
                            <option value="Maldonado">Maldonado</option>
                            <option value="Montevideo">Montevideo</option>
                            <option value="Paysandú">Paysandú</option>
                            <option value="Río Negro">Río Negro</option>
                            <option value="Rivera">Rivera</option>
                            <option value="Rocha">Rocha</option>
                            <option value="Salto">Salto</option>
                            <option value="San José">San José</option>
                            <option value="Soriano">Soriano</option>
                            <option value="Tacuarembó">Tacuarembó</option>
                            <option value="Treinta y Tres">Treinta y Tres</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="Description" className="form-label">Descripción</label>
                        <textarea type="text" className="form-control"
                            id="Description"
                            name="description"
                            onChange={handleInputChange}
                            placeholder='Agrega comentarios acerca del lote'
                        />
                    </div>

                    <div className="mb-3">
                        <div className="btn-group" role="group">
                            <input type="radio" className="btn-check"
                                id="Public"
                                name="isPublic"
                                defaultChecked
                                onClick={() => setPostForm({ ...postForm, isPublic: true })}>
                            </input>
                            <label className="btn btn-outline-primary" htmlFor="Public">Público</label>

                            <input type="radio" className="btn-check"
                                id="Private"
                                name="isPublic"
                                onClick={() => setPostForm({ ...postForm, isPublic: false })}>
                            </input>
                            <label className="btn btn-outline-primary" htmlFor="Private">Privado</label>
                        </div>
                    </div>
                    
                    <div className="mb-3">
                        <label htmlFor="content" className="form-label">Contenido</label>
                        <CreateContent onFilesSelected={handleFilesSelected} />
                    </div>

                    <button type="submit" className="btn btn-primary">Crear</button>

                </form>
            </div>
        </>
    );
}
