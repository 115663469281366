import React from "react";

function LoadingScreen() {
    return (
        <div className="modal d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content" style={{ background: 'transparent', border: 'none' }}>
                    <div className="modal-body text-center">
                        <div className="spinner-border text-light" style={{ width: '3rem', height: '3rem' }} role="status">
                            <span className="visually-hidden">Cargando...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadingScreen;