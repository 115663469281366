import React, { useState } from "react";

const Loading = () => {
    const [isLoading, setIsLoading] = useState(false);

    const startLoading = () => setIsLoading(true);
    const stopLoading = () => setIsLoading(false);

    const renderWithLoading = (Component) => isLoading ?
        <div className="d-flex justify-content-center align-items-center my-3">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Cargando...</span>
            </div>
        </div>
        :
        Component || <Component/>;

    return { isLoading, startLoading, stopLoading, renderWithLoading };
}

export default Loading;