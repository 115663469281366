import React, { useState, useEffect } from 'react';
import { useUserHooks } from './user.hooks';
import { useUserContext } from '../../contexts/logged';
import Toast from '../utils/toast';
import Modal from "../utils/modal";
import { Popover } from 'bootstrap';

export const ChooseUser = () => {
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        business: '',
        RUT: '',
        location: '',
        telephone: '',
        logo: '',
        role: ''
    });
    const { userUpdate } = useUserContext();
    const { getUser, updateUser } = useUserHooks();

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const showErrorModal = (title, message) => {
        setModalMessage({ title, message });
        setShowModal(true);
    };

    const showErrorToast = (message) => {
        setToastMessage(message);
        setShowToast(true);
    };

    useEffect(() => {
        const fetchUser = async () => {
            const userData = await getUser();
            setUserData(userData);
        };
        fetchUser();

        const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
        popoverTriggerList.forEach((popoverTriggerEl) => {
            new Popover(popoverTriggerEl);
        });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevUser => ({ ...prevUser, [name]: value }));
    };

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setUserData(prevUser => ({ ...prevUser, logo: reader.result }));
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const title = 'Actualizar usuario';
        const message = 'Estás seguro que los datos son correctos?';
        showErrorModal(title, message);
    };

    const handleUpdate = async () => {
        try {
            await updateUser(userData);
            userUpdate(userData);
            window.location.href = '/';
        } catch (e) {
            showErrorToast("Error al actualizar usuario");
        }
    };

    return (
        <>
            <div className="container">
                <h1>Nuevo usuario</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="firstName" className="form-label">Nombre</label>
                        <input className="form-control"
                            type="text"
                            name="firstName"
                            value={userData.firstName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="lastName" className="form-label">Apellido</label>
                        <input className="form-control"
                            type="text"
                            name="lastName"
                            value={userData.lastName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="business" className="form-label">Nombre del negocio</label>
                        <input className="form-control"
                            type="text"
                            name="business"
                            value={userData.business}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="RUT" className="form-label">RUT</label>
                        <input className="form-control"
                            type="text"
                            name="RUT"
                            value={userData.RUT}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="Location" className="form-label">Departamento</label>
                        <select className="form-select" id="location" name="location"
                            value={userData.location} onChange={handleChange}>
                            <option value="">Selecciona un departamento</option>
                            <option value="Artigas">Artigas</option>
                            <option value="Canelones">Canelones</option>
                            <option value="Cerro Largo">Cerro Largo</option>
                            <option value="Colonia">Colonia</option>
                            <option value="Durazno">Durazno</option>
                            <option value="Flores">Flores</option>
                            <option value="Florida">Florida</option>
                            <option value="Lavalleja">Lavalleja</option>
                            <option value="Maldonado">Maldonado</option>
                            <option value="Montevideo">Montevideo</option>
                            <option value="Paysandú">Paysandú</option>
                            <option value="Río Negro">Río Negro</option>
                            <option value="Rivera">Rivera</option>
                            <option value="Rocha">Rocha</option>
                            <option value="Salto">Salto</option>
                            <option value="San José">San José</option>
                            <option value="Soriano">Soriano</option>
                            <option value="Tacuarembó">Tacuarembó</option>
                            <option value="Treinta y Tres">Treinta y Tres</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Teléfono</label>
                        <input className="form-control"
                            type="text" name="telephone"
                            placeholder="Celular de contacto"
                            value={userData.telephone} onChange={handleChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="content" className="form-label required">Logo</label>
                        <input className="form-control"
                            type="file" name="logo"
                            accept="image/*" onChange={handleLogoChange}
                        />
                    </div>
                    {userData.logo && (
                        <div className="mb-3">
                            <label className="form-label">Vista previa del Logo</label>
                            <img className="form-control" src={userData.logo} alt="Vista previa del logo" style={{ width: '100px' }} />
                        </div>
                    )}
                    <div className="mb-3">
                        <label htmlFor="role" className="form-label">Rol</label>
                        <select className="form-select"
                            name="role" value={userData.role}
                            onChange={handleChange}
                            data-bs-toggle="popover"
                            title={userData.role === 'client' ? 'Cliente' : 'Consignatario'}
                            data-bs-content={userData.role === 'client' ? 'Un cliente puede únicamente visualizar lotes.' : 'Un consignatario puede publicar y modificar lotes.'}
                        >
                            <option value='client'>Cliente</option>
                            <option value='consignee'>Consignatario</option>
                        </select>
                    </div>

                    <button type="submit" className="btn btn-primary">Actualizar</button>
                </form>
            </div>
            <Toast show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                modalTitle={modalMessage.title}
                message={modalMessage.message}
                buttons={[{ name: 'Volver', className: 'btn-secondary', action: () => setShowModal(false) }, { name: 'Confirmar', className: 'btn-primary', action: handleUpdate }]}
            />
        </>
    );
};
