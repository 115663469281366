import { React, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loading from '../utils/loading';
import AdBanner from '../utils/ad-banner';
import PostDetail from './post-detail';
import SpecieFilter from '../home/specie-filter';
import { postsService } from '../../services/post-services/posts';
import { Carousel } from '../contents/carousel';

export default function PostsResults({ prop, isPreview, isProfile }) {
    const location = useLocation();
    const [posts, setPosts] = useState(prop);
    const { startLoading, stopLoading, renderWithLoading } = Loading();

    const specie = new URLSearchParams(location.search).get('specie');
    const category = new URLSearchParams(location.search).get('category');
    const breed = new URLSearchParams(location.search).get('breed');
    const ubication = new URLSearchParams(location.search).get('ubication');

    useEffect(() => {
        if (!posts) {
            function fetchPosts() {
                postsService.getPosts({ specie, category, breed, ubication }).then((response) => {
                    setPosts(isPreview ? response.data.slice(0, 6) : response.data);
                    stopLoading();
                });
            }
            fetchPosts();
        } else if (specie) {
            function fetchSpeciePosts() {
                startLoading();
                postsService.getPosts({ specie, category, breed, ubication }).then((response) => {
                    setPosts(response.data);
                    stopLoading();
                });
            }
            fetchSpeciePosts();
        }
    }, [location.search]);

    const [selectedPost, setSelectedPost] = useState(null);

    const handlePostClick = (id) => {
        const selected = posts.find((post) => post.id === id);
        if (selected) {
            setSelectedPost(selected);
        }
    };

    const handlePostUpdate = (updatedPost) => {
        setPosts(posts.map(post => post.id === updatedPost.id ? updatedPost : post)
            .filter(post => post.isPublic));
        if (isProfile)
            setPosts(posts.map(post => post.id === updatedPost.id ? updatedPost : post));
    };

    return (
        <>
            {selectedPost && (
                <PostDetail
                    prop={selectedPost}
                    handleClose={() => setSelectedPost(null)}
                    onUpdate={handlePostUpdate}
                />
            )}

            {specie && <SpecieFilter specie={specie} />}

            {renderWithLoading(
                <div className='container-fluid bg-light-subtle p-3'>
                    <div className="row row-cols-1 row-cols-md-4 gap-3 justify-content-md-center">
                        {posts && posts.map((post) => {
                            return (
                                <div id={post.id} key={post.id} className="card" role="button" onClick={(e) => handlePostClick(e.currentTarget.closest('.card').id)}>
                                    <div className="row g-2 mt-2">
                                        <Carousel postId={post.id} contents={post.media}/>
                                        <div className="card-body">
                                            <h5 className="card-title">{post.title}</h5>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <AdBanner></AdBanner>
        </>
    );
}