import React from 'react';
import Loading from '../utils/loading';
import { useContentsHooks } from '../contents/contents.hooks';
import { CreateContent } from './create';

export function Carousel({ postId, contents, editMode = false, onFilesSelected }) {

    const { isLoading, renderWithLoading } = Loading();
    const { removeFile } = useContentsHooks();

    const handleFilesSelected = (selectedFiles) => {
        onFilesSelected(selectedFiles);
    };

    const handleRemove = async (contentId) => {
        await removeFile(contentId);
    }

    return (
        isLoading ? renderWithLoading() :
            <>
                <div className="card-media" onClick={(e) => e.stopPropagation()}>
                    <div id={`carousel_${postId}`} key={postId} className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            { contents && contents.length > 0 ? contents.map((content, index) => {
                                return (
                                    <div key={content.id} className={index === 0 ? 'carousel-item active' : 'carousel-item'}>
                                        {content.mimetype.startsWith('image/') ?
                                            <img key={index} src={content.url} className="d-block w-100" alt="..."></img>
                                            :
                                            <video key={index} controls="controls" preload="metadata" src={`${content.url}#t=0.1`} style={{ width: '100%' }}></video>
                                        }
                                        {editMode ? <button id="delete-content-btn" className="btn" onClick={() => handleRemove(content.id)}>X</button> : null}
                                    </div>
                                );
                            }) : ""}
                        </div>
                        <button className="carousel-control-prev align-self-center" type="button" data-bs-target={`#carousel_${postId}`} data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Anterior</span>
                        </button>
                        <button className="carousel-control-next align-self-center" type="button" data-bs-target={`#carousel_${postId}`} data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Siguiente</span>
                        </button>
                    </div>
                </div>
                <div className="mt-2">
                    {editMode ? <CreateContent onFilesSelected={handleFilesSelected} /> : null}
                </div>
            </>
    );
}