import { React } from 'react';
import Loading from '../utils/loading';
import logo from '../../assets/logo/vinculo-ganadero.svg';

import Divider from '../root/divider';
import Posts from '../posts/posts';
import AdBanner from '../utils/ad-banner';

export default function HomeContent() {

    const { renderWithLoading } = Loading();

    const homeImages = [
        'image (1).jpg',
        'image (2).jpg',
        'image (3).jpg',
        'image (4).jpg',
        'image (5).jpg',
        'image (6).jpg',
        'image (7).jpg',
        'image (8).jpg',
        'image (9).jpg',
        'image (10).jpg',
    ];

    return (
        <>
            <AdBanner></AdBanner>

            <div className="text-center card text d-flex align-items-center rounded-0">
                <div id="carousel" className="carousel slide" data-bs-ride="carousel">
                    <div id="home-poster" className="carousel-inner">
                        {homeImages.map((image, index) => {
                            return (
                                <div key={image} className={index === 0 ? 'carousel-item active' : 'carousel-item'}>
                                    <img src={`${process.env.PUBLIC_URL}/images/${image}`} className="d-block w-100" alt="..."></img>
                                </div>
                            );
                        })
                        }
                    </div>
                </div>
                <div className="card-img-overlay d-flex justify-content-center align-items-center">
                    <div className="row w-100">
                        <div className="col-8 offset-2 d-flex justify-content-center">
                            <img
                                className='w-100'
                                src={logo}
                                alt="logo"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Divider props={[
                { title: "ultimos lotes publicados" },
                { title: "ver todos >", onClick: "/posts" }]}>
            </Divider>

            {renderWithLoading(<Posts isPreview={true} />)}

        </>
    );
}