import React from "react";

export default function FetchError(props) {
    const { error, resetErrorBoundary } = props;

    return (
        <div className="container">
            <h1>Perdón...</h1>
            <p>Ha ocurrido un error.</p>
            <p> <i>{error.data}</i> </p>
            <button onClick={resetErrorBoundary}>Intenta de nuevo</button>
        </div>
    );
}