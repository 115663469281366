import React from 'react';

function ConfirmAccount() {
  return (
    <div className="card" style={{ maxWidth: '500px', margin: 'auto', marginTop: '50px', padding: '20px' }}>
      <div className="card-body">
        <h5 className="card-title">Confirma tu cuenta</h5>
        <p className="card-text">
          Hemos enviado un correo electrónico a tu dirección de correo electrónico. Por favor, sigue las instrucciones en el correo para confirmar tu cuenta.
        </p>
      </div>
    </div>
  );
}

export default ConfirmAccount;