import { api } from '../api-services'

const url = "/contents"

const getContentFromPost = async(postId) => {
    return await api.get(`${url}/${postId}`);
}

const deleteContent = async(contentId) => {
    return await api.del(`${url}/${contentId}`);
}

export const contentsService = {
    getContentFromPost,
    deleteContent
}