import React from 'react';
import "../../index.scss";

import UserProvider from '../../contexts/logged';
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from '../utils/error-page';
import { Outlet } from 'react-router-dom';

import Header from './header';
import Footer from './footer';

function App() {
    return (
        <UserProvider>
            <ErrorBoundary FallbackComponent={ErrorPage}>
                <div>
                    <Header></Header>
                    <Outlet></Outlet>
                </div>
                <Footer></Footer>
            </ErrorBoundary>
        </UserProvider>
    );
}

export default App;
