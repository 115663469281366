import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "./index.scss";

import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

import { GoogleOAuthProvider } from '@react-oauth/google';
import PrivateRoutes from "./hooks/private-routes.jsx";

import Root from "./routes/root/root.jsx";
import Home from "./routes/home/home.jsx";
import About from "./routes/home/about.jsx";
import Contact from "./routes/home/contact.jsx";

import Posts from "./routes/posts/posts.jsx";
import PostCreate from "./routes/posts/post-create.jsx";
import PostDetail from "./routes/posts/post-detail.jsx";

import SignUp from "./routes/users/signup.jsx";
import SignIn from "./routes/users/login.jsx";
import { ChooseUser } from "./routes/users/choose.jsx";

import Profile from "./routes/users/profile.jsx";
import ProfileEdit from "./routes/users/profile-edit.jsx";

import ConfirmYourAccount from "./routes/helpers/confirm-your-account.jsx";
import ConfirmPage from "./routes/helpers/confirm-account.jsx";
import ForgotPassword from "./routes/helpers/forgot-password.jsx";
import ResetPassword from "./routes/helpers/reset-password.jsx";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "register",
                element: <SignUp />,
            },
            {
                path: "login",
                element: <SignIn />,
            },
            {
                path: "choose",
                element: <ChooseUser />,
            },
            {
                path: "about",
                element: <About />
            },
            {
                path: "contact",
                element: <Contact />
            },
            {
                path: "confirm",
                element: <ConfirmYourAccount />
            },
            {
                path: "confirm/:token",
                element: <ConfirmPage />
            },
            {
                path: "forgot-password",
                element: <ForgotPassword />
            },
            {
                path: "reset-password", 
                element: <ResetPassword />
            },
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/posts/:id",
                element: <PostDetail />
            },
            {
                element: <PrivateRoutes />,
                children: [
                    
                    {
                        path: "/profile",
                        element: <Profile />
                    },
                    {
                        path: "/edit-profile",
                        element: <ProfileEdit />
                    },
                    {
                        path: "/create-post",
                        element: <PostCreate />
                    },
                    {
                        path: "/posts",
                        element: <Posts />,
                    }
                ]
            }
        ],
    },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <GoogleOAuthProvider clientId="1036405022-rvkvvh6ndatvru3qr5b9u6t0vka4a4eg.apps.googleusercontent.com">
        {/* <React.StrictMode> */}
        <RouterProvider router={router} />
        {/* </React.StrictMode> */}
    </GoogleOAuthProvider>
);
