import { api } from '../api-services'

const url = "/posts"

const create = async (post) => {
    const headers = {
        'Content-Type': 'multipart/form-data'
    };
    return await api.post(url, post, true, headers);
};

const getPosts = async(criteria) => {
    return await api.get(url, true, criteria);
}

const GetPostsByUser = async(userId) => {
    return await api.get(`${url}/user/${userId}`);
}

const getPost = async(id) => {
    return await api.get(`${url}/${id}`);
}

const update = async(post) => {
    const headers = {
        'Content-Type': 'multipart/form-data'
    };
    return await api.put(url, post, true, headers);
}

const remove = async(id) => {
    return await api.del(`${url}/${id}`);
}

export const postsService = {
    create,
    getPosts,
    GetPostsByUser,
    getPost,
    update,
    remove
}