import Loading from '../utils/loading';
import { usersService } from '../../services/user-services/users';
import { useUserContext } from '../../contexts/logged';

export const useUserHooks = () => {
    const { startLoading, stopLoading } = Loading();
    const { user } = useUserContext();

    const login = async (email, password) => {
        startLoading();
        const response = await usersService.login(email, password);
        stopLoading();
        return response.data;
    };

    const googleAuth = async (credential) => {
        startLoading();
        const response = await usersService.authenticate(credential);
        stopLoading();
        return response.data;
    };

    const createUser = async (user) => {
        startLoading();
        const response = await usersService.createUser(user);
        stopLoading();
        return response.data;
    };

    const getUser = async () => {
        startLoading();
        const response = await usersService.getUser(user.id);
        stopLoading();
        return response.data.user;
    };

    const updateUser = async (user) => {
        startLoading();
        const response = await usersService.updateUser(user);
        stopLoading();
        return response.data;
    };

    return { login, googleAuth, createUser, getUser, updateUser };
};
