import { utilsService } from '../../services/util-services/utils';
import { postsService } from "../../services/post-services/posts";
import Loading from '../utils/loading';

export const usePostsHooks = () => {

    const { startLoading, stopLoading } = Loading();

    const createForm = (data) => {
        const formData = new FormData();
    
        const appendFormData = (formData, key, value) => {
            if (Array.isArray(value)) {
                value.forEach(item => {
                    formData.append(key, item);
                });
            } else if (value instanceof Object && !(value instanceof File)) {
                Object.keys(value).forEach(subKey => {
                    appendFormData(formData, `${key}[${subKey}]`, value[subKey]);
                });
            } else {
                formData.append(key, value);
            }
        };
    
        Object.keys(data).forEach(key => {
            appendFormData(formData, key, data[key]);
        });
    
        return formData;
    };

    const createPost = async (post) => {
        startLoading();
        const form = createForm(post);
        await postsService.create(form);
        stopLoading();
    };

    const fetchBreeds = async (specie = 'cattle') => {
        const response = await utilsService.getBreeds(specie);
        return response.data;
    };
    
    const fetchCategories = async (specie = 'cattle') => {
        const response = await utilsService.getCategories(specie);
        return response.data;
    };

    const updatePost = async (post) => {
        startLoading();
        const form = createForm(post);
        const response = await postsService.update(form);        
        stopLoading();
        return response.data.post[0];
    };

    const removePost = async (postId) => {
        startLoading();
        await postsService.remove(postId);
        stopLoading();
    }

    return { createPost, fetchBreeds, fetchCategories, updatePost, removePost };
};