import { React, useState} from 'react';
import { utilsService } from '../../services/util-services/utils';

const Contact = () => {

    const [emailForm, setEmailForm] = useState({
        name: '',
        email: '',
        message: ''
    });

    const sendEmail = () => {
        utilsService.sendEmail(emailForm);
    };

    return (
        <div className="card w-75 m-auto">
            <div className="card-body">
                <h2 className='text-center'>Haz tu consulta aquí</h2>
                <form>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Nombre:</label>
                        <input type="text" className="form-control" 
                            id="name"
                            name="name"
                            onChange={(e) => { setEmailForm({ ...emailForm, [e.target.name]: e.target.value }) }}
                            />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Correo electrónico:</label>
                        <input type="email" className="form-control" 
                            id="email"
                            name="email"
                            onChange={(e) => { setEmailForm({ ...emailForm, [e.target.name]: e.target.value }) }}
                            />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="message" className="form-label">Mensaje:</label>
                        <textarea className="form-control"
                            id="message" 
                            name="message" 
                            rows="2"
                            onChange={(e) => { setEmailForm({ ...emailForm, [e.target.name]: e.target.value }) }}
                            />
                    </div>

                    <button type="button" className="btn btn-primary" onClick={sendEmail}>Enviar</button>
                </form>
            </div>
        </div>
    );
};

export default Contact;