import React, { useState } from 'react';
import Toast from '../utils/toast';
import AdBanner from '../utils/ad-banner';
import { NavLink } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useUserContext } from '../../contexts/logged';
import { useUserHooks } from './user.hooks';

function SignInForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, googleAuth } = useUserHooks();
    const { userLogin } = useUserContext();

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const showErrorToast = (message) => {
        setToastMessage(message);
        setShowToast(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await login(email, password).then((response) => {
                userLogin(response.user, response.token);
                window.location.href = "/";
            });
        } catch (error) {
            showErrorToast("Error al iniciar sesión");
        }
    };

    const responseMessage = async (googleResponse) => {
        await googleAuth(googleResponse.credential).then((response) => {
            userLogin(response.user, response.token);
            response.isNew === true ? (window.location.href = '/choose') : window.location.href = '/';
        });
    };

    return (
        <>
            <AdBanner></AdBanner>
            <div className="container">
                <h1>Iniciar Sesión</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="formEmail" className="form-label">Email</label>
                        <input className="form-control"
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} />
                    </div>
                    <div className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <label htmlFor="formPassword" className="form-label">Contraseña</label>
                            <NavLink to='/forgot-password' className="nav-link form-label">Olvidaste tu contraseña?</NavLink>
                        </div>
                        <input className="form-control"
                            type="password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                    </div >

                    <button type="submit" className="btn btn-primary">Iniciar sesión</button>
                    <NavLink to='/register' className="btn">No tienes cuenta? <span className='btn-link'>Regístrate</span></NavLink>
                </form>

                <h5 className='mt-3 text-center'>O continúa con Google</h5>
                <div className='my-3 justify-content-center d-flex'> <GoogleLogin onSuccess={responseMessage} onError={(error) => { setToastMessage(error.message) }}/></div>
                <Toast show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            </div>
        </>
    );
}

export default function SignIn() {
    return (
        <SignInForm></SignInForm>
    );
};