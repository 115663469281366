import React, { useState } from 'react';
import Toast from '../utils/toast';
import { useLocation } from 'react-router-dom';
import { usersService } from '../../services/user-services/users';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {

    const query = useQuery();
    const [password, setPassword] = useState({ Password: '', ConfirmPassword: '' });
    const email = query.get('email');
    const token = query.get('token');

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const showErrorToast = (message) => {
        setToastMessage(message);
        setShowToast(true);
    };


    const handleChange = (e) => {
        setPassword({ ...password, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password.Password !== password.ConfirmPassword) {
            showErrorToast("Las contraseñas no coinciden");
            return;
        }
        try {
            await usersService.resetPassword(email, token, password.Password).then(() => {
                window.location.href = '/login';
            });
        } catch (error) {
            if (error.response.data.title != null) 
                showErrorToast(error.response.data.title);

            showErrorToast("Token inválido");
        }
    };

    return (
        <div className='container'>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label">Contraseña</label>
                    <input className="form-control"
                        type="password"
                        name="Password"
                        onChange={handleChange} />
                    <div className="form-text" id="basic-addon4">Mínimo 8 caracteres. Al menos una mayúscula, un número y un caractér especial</div>
                </div>
                <div className="mb-3">
                    <label className="form-label">Confirmar contraseña</label>
                    <input className="form-control"
                        type="password"
                        name="ConfirmPassword"
                        onChange={handleChange} />
                </div>
                <button type="submit" className="btn btn-primary">Confirmar</button>
            </form>
            <Toast show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
        </div>
    );
};

export default ResetPassword;