import { React, useState, useEffect } from 'react';
import AdBanner from '../utils/ad-banner';
import { useNavigate, createSearchParams } from "react-router-dom";
import { utilsService } from '../../services/util-services/utils';

export default function Poster({specie}) {

    function renderSwitch() {
        switch (specie) {
            case 'cattle':
                return 'image (10).jpg';
            case 'sheep':
                return 'image (4).jpg';
            case 'horse':
                return 'image (2).jpg';
            default:
                return '';
        }
    }

    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [choosenCategories, setChoosenCategories] = useState('');
    const [breeds, setBreeds] = useState([]);
    const [choosenBreeds, setChoosenBreeds] = useState('');
    const [ubication, setUbication] = useState('');

    useEffect(() => {
        async function fetchData() {
            const categoriesResponse = await utilsService.getCategories(specie);
            setCategories(categoriesResponse.data);
            const breedsResponse = await utilsService.getBreeds(specie);
            setBreeds(breedsResponse.data);
        }
        fetchData();
    }, [specie]);

    async function search() {
        let params = { specie: specie, category: choosenCategories, breed: choosenBreeds, ubication: ubication };
        navigate({
            pathname: '/posts',
            search: `?${createSearchParams(params)}`,
        });
    }

    return (
        <>
            <AdBanner></AdBanner>

            <div className="text-center card text d-flex align-items-center">
                <img id="home-poster" src={`${process.env.PUBLIC_URL}/images/${renderSwitch()}`} className="d-block w-100" alt="..."></img>

                {specie &&
                    <div className="card-img-overlay">

                        <div className="container-lg h-75 d-flex flex-column justify-content-center">
                            <div className="row justify-content-center mb-2">
                                <div className="col-4 col-sm-3">
                                    <div className="p-2">Filtros:</div>
                                </div>
                                <div className="col-4 col-sm-3">
                                    <select className="form-select" onChange={(e) => setChoosenCategories(e.target.value)}>
                                        <option value="" defaultValue selected>Categoría</option>
                                        {categories.map((category) => {
                                            return (
                                                <option key={category.name} value={category.name}>{category.name}</option>);
                                        })}
                                    </select>
                                </div>
                                <div className="col-4 col-sm-3">
                                    <select className="form-select" onChange={(e) => setChoosenBreeds(e.target.value)}>
                                        <option value="" defaultValue selected>Raza</option>
                                        {breeds.map((breed) => {
                                            return (
                                                <option key={breed.name} value={breed.name}>{breed.name}</option>);
                                        })}
                                    </select>
                                </div>

                            </div>
                            <div className="row justify-content-center">
                                <div className="col-4 col-sm-3">
                                    <div className="mb-3">
                                        <select className="form-select" id="Ubication" name="Ubication" onChange={(e) => setUbication(e.target.value)}>
                                            <option value="" defaultValue selected>Departamento</option>
                                            <option value="Artigas">Artigas</option>
                                            <option value="Canelones">Canelones</option>
                                            <option value="Cerro Largo">Cerro Largo</option>
                                            <option value="Colonia">Colonia</option>
                                            <option value="Durazno">Durazno</option>
                                            <option value="Flores">Flores</option>
                                            <option value="Florida">Florida</option>
                                            <option value="Lavalleja">Lavalleja</option>
                                            <option value="Maldonado">Maldonado</option>
                                            <option value="Montevideo">Montevideo</option>
                                            <option value="Paysandú">Paysandú</option>
                                            <option value="Río Negro">Río Negro</option>
                                            <option value="Rivera">Rivera</option>
                                            <option value="Rocha">Rocha</option>
                                            <option value="Salto">Salto</option>
                                            <option value="San José">San José</option>
                                            <option value="Soriano">Soriano</option>
                                            <option value="Tacuarembó">Tacuarembó</option>
                                            <option value="Treinta y Tres">Treinta y Tres</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-4 col-sm-3">
                                    <button type="button" className="btn btn-primary" onClick={search}>Buscar</button>
                                </div>
                            </div>
                        </div>

                    </div>}

            </div>
        </>
    );
}