import React, { useEffect, useState } from 'react';
import Toast from '../utils/toast';
import AdBanner from '../utils/ad-banner';
import { useUserContext } from '../../contexts/logged';
import { useUserHooks } from './user.hooks';

const ProfileEdit = () => {

    const { user, setUser } = useUserContext();
    const { getUser, updateUser } = useUserHooks();

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const showErrorToast = (message) => {
        setToastMessage(message);
        setShowToast(true);
    };

    const [userData, setUserData] = useState({
        id: user.id,
        firstName: '',
        lastName: '',
        business: '',
        telephone: '',
        logo: null
    });

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        async function fetchUser() {
            await getUser(user.id).then((response) => {
                setUserData({ ...response });
            })
        } fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setUserData({ ...userData, logo: reader.result });
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedUser = await updateUser(userData);
        setUser({ ...updatedUser });
        window.location.href = '/profile';
    };

    return (
        <>
            <AdBanner></AdBanner>

            <div className='container'>
                <h2>Editar Perfil</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label required">Nombre</label>
                        <input className="form-control"
                            type="text" name="firstName"
                            placeholder={userData.firstName}
                            onChange={handleChange} />
                    </div>
                    <div className="mb-3">
                        <label className="form-label required">Apellido</label>
                        <input className="form-control"
                            type="text" name="lastName"
                            placeholder={userData.lastName}
                            onChange={handleChange} />
                    </div>

                    {user.role == "consignee" && (
                        <>
                            <div className="mb-3">
                                <label className="form-label required">Nombre del negocio</label>
                                <input className="form-control"
                                    type="text" name="business"
                                    placeholder={userData.business}
                                    onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label required">Contacto</label>
                                <input className="form-control"
                                    type="text" name="telephone"
                                    placeholder={userData.telephone}
                                    onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="content" className="form-label required">Logo</label>
                                <input className="form-control"
                                    type="file" name="logo"
                                    accept="image/*"
                                    onChange={handleLogoChange}
                                />
                            </div>
                            {userData.logo && (
                                <div className="mb-3">
                                    <label className="form-label">Vista previa del Logo</label>
                                    <img className="form-control" src={userData.logo} alt="Vista previa del logo" style={{ width: '100px' }} />
                                </div>
                            )}
                        </>
                    )}

                    <button type="submit" className="btn btn-primary">Editar</button>
                </form>
                <Toast show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            </div>
        </>

    );
};

export default ProfileEdit;