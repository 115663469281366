import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../contexts/logged';
import LoadingScreen from '../utils/loading-screen';
import PostEdit from './post-edit';
import { Carousel } from '../contents/carousel';
import { postsService } from '../../services/post-services/posts';

export default function PostDetail({ prop, handleClose = () => window.location.href = '/', onUpdate }) {

    const { id } = useParams();
    const [post, setPost] = useState({});
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const { user } = useUserContext();

    useEffect(() => {
        if (id) {
            async function fetchData() {
                await postsService.getPost(id).then((response) => {
                    setPost(response.data[0]);
                    setLoading(false);
                });
            }
            fetchData();
        } else {
            setPost(prop);
            setLoading(false);
        }
    }, [id]);

    const copyToClipboard = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const href = e.currentTarget.getAttribute('href');
        navigator.share({
            title: 'Vínculo Ganadero',
            text: 'Mira esta publicación!',
            url: href,
        })
    }

    const handlePostUpdate = (updatedPost) => {
        setPost(updatedPost);
        onUpdate(updatedPost);
    };

    const editPost = () => {
        setEditMode(true);
    };

    return (
        loading ? <LoadingScreen /> :
            editMode ? <PostEdit prop={post} handleClose={handleClose} setEditMode={setEditMode} onUpdate={handlePostUpdate} /> :
                <>
                    <div id="postModal" tabIndex="-1" className="modal d-block" aria-hidden="true" onClick={handleClose}>
                        <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{post.title}</h5>
                                    <button type="button" className="btn-close" onClick={handleClose}></button>
                                </div>
                                <div className="modal-body">
                                    <Carousel postId={post.id} contents={post.media} />
                                    <div className="container mt-3">
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <p>Categorías:
                                                {post.lot.categories.map((category, index) => (
                                                    index === 0 ? <span key={category.id}> {category.name.toLowerCase()}</span> :
                                                    <span key={category.id}>, {category.name.toLowerCase()}</span>
                                                ))}
                                                </p>
                                            </div>
                                            <div className="col-sm">
                                                <p>Razas:
                                                {post.lot.breeds.map((breed, index) => (
                                                    index === 0 ? <span key={breed.id}> {breed.name}</span> :
                                                    <span key={breed.id}>, {breed.name}</span>
                                                ))}
                                                </p>
                                            </div>
                                            <div className="col-sm-8">
                                                <p>Peso estimado: {post.lot.weight}</p>
                                            </div>
                                            <div className="col-sm">
                                                <p>Cantidad: {post.lot.quantity}</p>
                                            </div>
                                            <div className="col-sm-8">
                                                <p>Ubicación: {post.ubication}</p>
                                            </div>
                                            <div className="col-sm">
                                                <p>Precio: ${post.price}</p>
                                            </div>
                                            <div className="col-sm-8">
                                                <p>Detalles: {post.description}</p>
                                            </div>
                                            <div className="col-sm">
                                                <p>Edad: {post.lot.age}</p>
                                            </div>
                                            <div className="col-xxl d-flex align-items-center">
                                                <>Publicado por: {post.user.business}</>
                                                <img src={`${post.user.logo}`}
                                                    style={{ height: "36px" }}
                                                    className="ms-1"
                                                    alt="User Logo" />
                                            </div>
                                            <div className="col text-end">
                                                <a className="btn btn-outline-primary btn-sm m-1" href={`https://api.whatsapp.com/send?phone=598${post.user.telephone}`} role="button">Contactar</a>
                                                <a className="btn btn-outline-primary btn-sm m-1" onClick={(e) => copyToClipboard(e)} href={`/posts/${post.id}`} role="button">Compartir</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <p className="card-text"><small className="text-body-secondary">{new Date(post.datePublished).toLocaleDateString('es-MX', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}</small></p>
                                    {user && user.id === post.user.id && (
                                        <button type="button" className="btn btn-secondary" onClick={editPost}>Editar</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show"></div>
                </>
    );
    
}