import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usersService } from '../../services/user-services/users';

const ConfirmPage = () => {
    const { token } = useParams();

    useEffect(() => {
        usersService.confirmUser(token).then(() => {
            setTimeout(() => {
                window.location.href = '/login';
            }, 3000);
        });
    }, [token]);

    return (
        <div className='container'>
            <h1 className='text-center'>Cuenta confirmada! Puedes acceder al sitio</h1>
        </div>
    );
};

export default ConfirmPage;
