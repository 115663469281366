import { NavLink } from 'react-router-dom';
import chain from '../../assets/logo/chain.svg';

export default function Footer() {
    return (
        <div id="footer" className="d-flex justify-content-evenly mt-4 pt-4 pb-4 bg-secondary">
            <div id="contact">
                <p className="text-center">
                    <span className="d-block">
                        <img className='me-1' style={{ height: 32, width: 32 }} src={chain} alt='logo'/>
                        <span className='fw-bold'>Vínculo Ganadero</span>
                    </span>
                </p>
                <p><span className='fw-bolder'>Dirección:</span> Artigas 1146, Salto </p>
                <p><span className='fw-bolder'>Contacto:</span> +598 99 486 455 </p>
                <a href='https://www.instagram.com/vinculoganadero' target="_blank" rel="noreferrer" className="nav-link">
                    <p><span className="d-block">
                        <img className='me-1' style={{ height: 16, width: 16 }} alt='instagram-logo'
                            src={`${process.env.PUBLIC_URL}/images/extra/instagram.png`} />
                        Instagram
                    </span></p>
                </a>
            </div>
            <div id="about-us">
                <NavLink to='/about' className="nav-link"> <p className="text-center fw-bold">Nosotros</p> </NavLink>
                <NavLink to='/about#us' className="nav-link"> <p>¿Quiénes somos?</p> </NavLink>
                <NavLink to='/about#mission' className="nav-link"> <p>Misión</p> </NavLink>
                <NavLink to='/about#vision' className="nav-link"> <p>Visión</p> </NavLink>
                <NavLink to='/contact' className="nav-link"> <p>Contactános</p> </NavLink>
                
            </div>
            {/* <div id="app">
                <p className="text-center">Bajate la app</p>
            </div> */}
        </div>
    );
}