"use client"

import React, { createContext, useState, useContext } from 'react';
import { jwtDecode } from "jwt-decode";

export const UserContext = createContext(null);

export default function LoggedUserProvider({ children }) {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('current-user')));

    const userLogin = (user, token) => {
        localStorage.setItem('current-user', JSON.stringify(user));
        localStorage.setItem('current-token', token);
        handleTokenExpiration(token);
        setUser(user);
    };

    const userUpdate = (userData) => {
        localStorage.removeItem('current-user');
        localStorage.setItem('current-user', JSON.stringify(userData));
        setUser({ ...user, ...userData });
    };

    const handleTokenExpiration = (token) => {
        if (!token) return;

        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const timeLeft = decodedToken.exp - currentTime;

        if (timeLeft <= 0) {
            onTokenExpired();
        } else {
            const timeoutId = setTimeout(onTokenExpired, timeLeft * 1000);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }

    const onTokenExpired = () => {
        localStorage.clear();
        window.location.href = '/login';
        setUser(null);
    };

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                userLogin,
                userUpdate
            }}>
            {children}
        </UserContext.Provider>
    );
};

export function useUserContext() {
    const context = useContext(UserContext);
    if (!context)
        throw new Error('useUserContext must be used within a UserProvider');

    return context;
}