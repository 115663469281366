import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoutes = () => {
    // let auth = localStorage.getItem('current-token');
    // return(
    //     auth ? <Outlet/> : <Navigate to="/login"/>
    // );
    return <Outlet/>
}

export default PrivateRoutes