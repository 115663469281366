import React from 'react';
import { NavLink } from "react-router-dom";

export default function Divider(props) {
    return (
        <div id="divider" className="d-flex justify-content-evenly bg-tertiary">
            {props.props.map((prop, index) => {
                return (
                    !prop.onClick ? <div key={index} className='px-xs-4'>{prop.title.toUpperCase()}</div> 
                    : 
                    <div key={index} className='px-xs-4'> 
                        <NavLink to={prop.onClick} className="nav-link">{prop.title.toUpperCase()}</NavLink>
                    </div> 
                );
            })}
        </div>
    );
}