import React, { useState, useEffect } from "react";
import Toast from '../utils/toast';
import Modal from "../utils/modal";
import { usePostsHooks } from './posts.hooks';
import { Carousel } from '../contents/carousel';

const PostEdit = ({ prop, handleClose, setEditMode, onUpdate }) => {

    const { fetchBreeds, fetchCategories, updatePost, removePost } = usePostsHooks();

    const [post, setPost] = useState(prop);
    const [loadedMedia, setLoadedMedia] = useState(prop.media);
    const [categories, setCategories] = useState([]);
    const [breeds, setBreeds] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const showErrorModal = (title, message) => {
        setModalMessage({ title, message });
        setShowModal(true);
    };

    const showErrorToast = (message) => {
        setToastMessage(message);
        setShowToast(true);
    };

    const cancelEdit = () => {
        setEditMode(false);
    };

    useEffect(() => {
        async function fetchData() {
            setBreeds(await fetchBreeds(post.lot.specie));
            setCategories(await fetchCategories(post.lot.specie));
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDeletePost = () => {
        const title = "Estás a punto de eliminar esta publicación."
        const message = "Seguro quieres eliminarla? Esta acción no se puede deshacer.";
        showErrorModal(title, message);
    }

    const handleFilesSelected = (selectedFiles) => {
        setPost(prevPost => ({
            ...prevPost,
            media: [...selectedFiles]
        }));
        console.log(selectedFiles);
    };

    const handleCategoriesChange = (e) => {
        let selectedValues = Array.from(e.target.selectedOptions, option => option.value);
        setPost(prevPost => ({
            ...prevPost,
            lot: { ...prevPost.lot, categories: selectedValues }
        }));
    };
    
    const handleBreedsChange = (e) => {
        let selectedValues = Array.from(e.target.selectedOptions, option => option.value);
        setPost(prevPost => ({
            ...prevPost,
            lot: { ...prevPost.lot, breeds: selectedValues }
        }));
    };    

    const deletePost = async () => {
        try {
            await removePost(post.id);
            cancelEdit();
            window.location.href = "/profile";
        } catch (error) {
            showErrorToast("Error al eliminar la publicación");
        }
    };

    const savePost = async () => {
        try {
            const formattedPost = {
                ...post,
                lot: {
                    ...post.lot,
                    categories: post.lot.categories.map(category => category.id || category),
                    breeds: post.lot.breeds.map(breed => breed.id || breed)
                }
            };
            const updatedPost = await updatePost(formattedPost);
            setPost(updatedPost);
            onUpdate(updatedPost);
            cancelEdit();
        } catch (error) {
            showErrorToast("Error al guardar los cambios");
        }
    };

    return (
        <>
            <div id="postModal" tabIndex="-1" className="modal d-block" aria-hidden="true" onClick={handleClose}>
                <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="input-group w-75">
                                <span className="input-group-text" id="inputGroup-sizing-default">Título</span>
                                <input type="text" name="title" className="form-control" value={post.title} onChange={(e) => setPost({ ...post, [e.target.name]: e.target.value })}></input>
                            </div>
                            <button type="button" className="btn-close" onClick={handleClose}></button>
                        </div>
                        <div className="modal-body">
                            <div className="container">
                                <div className="row mb-2">
                                    <Carousel postId={post.id} contents={loadedMedia} editMode={true} onFilesSelected={handleFilesSelected} />
                                </div>
                                <div className="row">
                                    <div className="col-sm-8 mb-2">
                                        <div className="input-group">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Categorías</span>
                                            <select className="form-select"
                                                name="categories"
                                                value={post.lot.categories}
                                                multiple
                                                onChange={handleCategoriesChange}
                                            >
                                                {categories.map((category) => {
                                                    return (
                                                        <option key={category.id} value={category.id}>{category.name}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm mb-2">
                                        <div className="input-group">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Cantidad</span>
                                            <input type="text" name="quantity" className="form-control" value={post.lot.quantity} onChange={(e) => setPost({ ...post, lot: { ...post.lot, [e.target.name]: e.target.value } })}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-8 mb-2">
                                        <div className="input-group">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Razas</span>
                                            <select className="form-select"
                                                name="breeds"
                                                value={post.lot.breeds}
                                                multiple
                                                onChange={handleBreedsChange}
                                            >
                                                {breeds.map((breed) => {
                                                    return (
                                                        <option key={breed.id} value={breed.id}>{breed.name}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm mb-2">
                                        <div className="input-group">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Edad</span>
                                            <input type="text" name="age" className="form-control" value={post.lot.age} onChange={(e) => setPost({ ...post, lot: { ...post.lot, [e.target.name]: e.target.value } })}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-8 mb-2">
                                        <div className="input-group">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Peso estimado</span>
                                            <input type="text" name="weight" className="form-control" value={post.lot.weight} onChange={(e) => setPost({ ...post, lot: { ...post.lot, [e.target.name]: e.target.value } })}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm">
                                        <div className="input-group">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Precio</span>
                                            <input type="text" name="price" className="form-control" value={post.price} onChange={(e) => setPost({ ...post, [e.target.name]: e.target.value })}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div>
                                        <div className="input-group">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Detalles</span>
                                            <textarea type="text" name="description" className="form-control" value={post.description} onChange={(e) => setPost({ ...post, [e.target.name]: e.target.value })}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-8 mb-2">
                                        <div className="input-group">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Ubicación</span>
                                            <input type="text" name="ubication" className="form-control" placeholder="Localidad, ubicación" value={post.ubication} onChange={(e) => setPost({ ...post, [e.target.name]: e.target.value })}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm align-self-center">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" name="isPublic" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={post.isPublic} onChange={(e) => setPost({ ...post, [e.target.name]: e.target.checked })}></input>
                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Público</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <p className="card-text"><small className="text-body-secondary">{new Date(post.datePublished).toLocaleDateString('es-MX', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</small></p>
                            <button type="button" className="btn btn-danger" onClick={handleDeletePost}>Eliminar</button>
                            <button type="button" className="btn btn-secondary" onClick={cancelEdit}>Cancelar</button>
                            <button type="button" className="btn btn-success" onClick={savePost}>Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop show"></div>
            <Toast show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                modalTitle={modalMessage.title}
                message={modalMessage.message}
                buttons={[{ name: 'Cancelar', className: 'btn-secondary', action: () => setShowModal(false) }, { name: 'Eliminar', className: 'btn-danger', action: deletePost }]}
            />
        </>
    );
}

export default PostEdit;
