import { React } from 'react';
import { NavLink } from "react-router-dom";
import logo from '../../assets/logo/vg.svg';
import { useUserContext } from '../../contexts/logged';

export default function Header() {
    const { user, setUser } = useUserContext()

    function SignOut() {
        localStorage.clear();
        setUser(null);
    }

    const handleNavLinkClick = () => {
        setTimeout(() => {
            const navbarCollapse = document.querySelector('.navbar-collapse');
            if (navbarCollapse.classList.contains('show')) {
                const navbarToggler = document.querySelector('.navbar-toggler');
                navbarToggler.click();
            }
        }, 150);
    };

    return (
        <nav className="navbar navbar-expand-lg bg-tertiary-subtle mb-4">
            <div className="container-fluid">
                <NavLink to={'/'} className="navbar-brand">
                    <img
                        src={logo}
                        style={{ height: 45 }}
                        alt="logo"
                    />
                </NavLink>
                <div className="row">
                    <div className="col nav-item fw-bold">
                        <NavLink to={'/posts?specie=cattle'} className="nav-link">Bovinos</NavLink>
                    </div>
                    <div className="col nav-item fw-bold">
                        <NavLink to={'/posts?specie=sheep'} className="nav-link">Ovinos</NavLink>
                    </div>
                    <div className="col nav-item fw-bold me-3">
                        <NavLink to={'/posts?specie=horse'} className="nav-link">Equinos</NavLink>
                    </div>
                </div>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        {user && user.role !== "client" ? (
                            <li className="nav-item">
                                <NavLink to={'/create-post'} className="nav-link fw-bold" onClick={handleNavLinkClick}>Publicar</NavLink>
                            </li>
                        ) : ""}
                        {user ? (
                            <li className="nav-item dropdown fw-bold">
                                <button className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    {`${user.firstName} ${user.lastName}`}
                                </button>
                                <ul className="dropdown-menu bg-tertiary">
                                    {user && user.role !== "client" ? (
                                        <li className="dropdown-item"> <NavLink to="/profile" className="nav-link" onClick={handleNavLinkClick}>Perfil</NavLink> </li>
                                    ) : ""}
                                    <li className="dropdown-item" onClick={SignOut}> <NavLink to={'/login'} className="nav-link" onClick={handleNavLinkClick}>Cerrar sesión</NavLink> </li>
                                </ul>
                            </li>
                        ) : (
                            <li className="nav-item">
                                <NavLink to="/login" className="nav-link" onClick={handleNavLinkClick}>Iniciar sesión</NavLink>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
}