import React, { useEffect } from 'react';

function Toast({ show, message, onClose }) {
    useEffect(() => {
        if (show) {
            const timeout = setTimeout(() => {
                onClose();
            }, 4000); // Cierra el toast después de 4 segundos
            return () => clearTimeout(timeout);
        }
    }, [show, onClose]);

    return (
        <div className="toast-container position-fixed bottom-0 end-0 p-3">
            <div className={`toast bg-primary text-white ${show ? 'show' : ''}`} role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header bg-primary text-white">
                    <strong className="me-auto">Error</strong>
                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close" onClick={onClose}></button>
                </div>
                <div className="toast-body bg-danger-subtle text-black">
                    {message}
                </div>
            </div>
        </div>
    );
}

export default Toast;