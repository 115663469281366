import React from 'react';

function CreateContent({ onFilesSelected }) {

    const handleInputChange = async (e) => {
        const files = Array.from(e.target.files);
        onFilesSelected(files);
    }

    return (
        <div>
            <input className="form-control" id="content"
                type="file"
                multiple
                accept="image/*, video/*"
                onChange={handleInputChange}
            />
        </div>
    );
}

export { CreateContent };