import { api } from '../api-services'

const getBreeds = (specie) => {
    return api.get(`/breeds/${specie}`);
}

const getCategories = async (specie) => {
    return api.get(`/categories/${specie}`);
}

const sendEmail = async (emailForm) => {
    return api.post('/email', emailForm);
}

export const utilsService = {
    getBreeds,
    getCategories,
    sendEmail
}