import { React } from 'react';

export default function About() {
    return (
        <div className='container mt-2'>
            <div id='us' className='container-fluid'>
                <h1 className='text-center'>¿Quiénes somos?</h1>
                Somos un equipo apasionado y comprometido con la creación de conexiones sólidas entre productores rurales y consignatarios. Nos caracteriza nuestra dedicación a facilitar oportunidades comerciales que impulsen el crecimiento y la prosperidad en el sector agrícola. Con una combinación única de experiencia en la industria y un enfoque innovador, nos esforzamos por ofrecer un servicio excepcional que satisfaga las necesidades de nuestros clientes y promueva el desarrollo sostenible en nuestras comunidades. Valoramos la transparencia, la integridad y la colaboración en todo lo que hacemos, y estamos comprometidos a construir relaciones duraderas basadas en la confianza y el respeto mutuo. Estamos orgullosos de nuestro papel como facilitadores de oportunidades y agentes de cambio en el mundo agrícola.
            </div>
            <div className='row mt-5'>
                <div id='mission' className='col'>
                    <h2 className='text-center'>Misión</h2>
                    En Vinculo Ganadero nos comprometemos a ser el puente confiable entre los productores rurales y los consignatarios, facilitando conexiones sólidas que amplíen el abanico de oportunidades para ambos. Nos esforzamos por ofrecer un servicio eficiente y transparente que impulse el crecimiento y la prosperidad de las comunidades ganaderas, promoviendo la colaboración y el desarrollo sostenible del sector.
                </div>
                <div id='vision' className='col'>
                    <h2 className='text-center'>Visión</h2>
                    En Vinculo Ganadero aspiramos a ser líderes reconocidos a nivel nacional en la vinculación entre productores rurales y consignatarios. Nos esforzamos por ser la primera opción para aquellos que buscan maximizar sus oportunidades comerciales en el sector ganadero, ofreciendo una plataforma innovadora y de confianza que impulse el desarrollo y la eficiencia en toda la cadena de valor. Visualizamos un futuro donde nuestra labor contribuya significativamente al crecimiento económico y la sustentabilidad del mundo agrícola, creando relaciones duraderas y beneficiosas para todas las partes involucradas.
                </div>
            </div>
        </div>
    );
}