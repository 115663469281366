import { contentsService } from '../../services/content-services/contents';
import Loading from '../utils/loading';

export const useContentsHooks = () => {

    const { startLoading, stopLoading } = Loading();

    const getPostMedia = async (postId) => {
        let media;
        startLoading();
        media = await contentsService.getContentFromPost(postId).then((response) => {
            stopLoading();
            return response.data;
        });
        return media;
    };

    const removeFile = async (contentId) => {
        startLoading();
        await contentsService.deleteContent(contentId);
        stopLoading();
    };

    return { removeFile, getPostMedia };
};